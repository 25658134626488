import React, { Component } from "react";
import notificationIcon from "../assets/header/ic_notification.png";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import LocationAddon from "../components/LocationAddOn";
import LanguageSwitcher from "./LanguageSwitcher";
import { FormattedMessage } from "react-intl";
import Avatar from "./Avatar";
import rewardsIcon from "../assets/header/ic_rewards.png";
import logoIcon from "../assets/logo-icon.png";
import wrpNewCoin from "../assets/header/wrpNewCoin.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DropDownMenuSelect from "react-nested-menu-selector";
import { Accordion, Card } from "react-bootstrap";
import right_arrow from "../assets/ic_right_arrow.svg";
import ic_hamburger from "../assets/ic_hamburger.svg";
import ic_back_arrow from "../assets/ic_back_arrow.svg";
import ic_down_arrow from "../assets/ic_down_arrow.svg";
import customerCommonClientX from "../utils/api/customerCommonClientX";
import { getVerticalCategory } from "../actions";
import { createCookie, readCookie } from "../utils/helper";
import { decryptByAesAndBase64WST } from "../utils/helper";
import profileClientX from "../utils/api/profileClientX";
import { setProfile, resetProfile } from "../actions";
import { removeCookie, setUserAccessToken } from "../utils/authorization";
import { getIsMob, getIsKamaie } from "../utils/helper";
export const accessTokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME;
let secret_key = process.env.REACT_APP_OTP_SECRET_KEY;
let secret_iv = process.env.REACT_APP_OTP_SECRET_IV;

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      isSidenavOpen: false,
      voucherBlock: false,
      affiliatesBlock: false,
      verticalCategory: [],
      // isMob: 0,
      // is_kamaie:0
      profile: null,
    };
  }
  componentDidMount() {
    this.props.getVerticalCategory();

    const queryParameters = new URLSearchParams(window.location.search);

    const queryIsMob = queryParameters.get("isMob");
    const queryIsKamaie = queryParameters.get("is_kamaie");
    const queryWst = queryParameters.get("wst");

    if (queryIsMob === "1" || queryIsMob === "0") {
      createCookie("isMob", queryIsMob, 0.125);
      localStorage.setItem('isMob', queryIsMob);
    }

    if (queryIsKamaie === "1" || queryIsKamaie === "0") {
      createCookie("is_kamaie", queryIsKamaie, 0.125);
      localStorage.setItem('is_kamaie', queryIsKamaie);
    }

    // if (queryWst) {
    //   createCookie("wst", queryWst);
    //   localStorage.setItem('wst', queryWst);
    // }
    // const wst = readCookie("wst");

    const parameterData = queryParameters.get(accessTokenName);

    const accessToken = decryptByAesAndBase64WST(
      parameterData,
      secret_key,
      secret_iv
    );
    if (accessToken) {
      setUserAccessToken(accessToken);
      this.getProfileData(accessToken);
    }
    queryParameters.delete('isMob');
    queryParameters.delete('is_kamaie');
    queryParameters.delete('wst')
    
    let newUrl = window.location.pathname;
     
    if (queryParameters.toString()) {
      newUrl += `?${queryParameters.toString()}`;
    }
    window.history.replaceState({}, document.title, newUrl);
  }
  getProfileData = (accessToken) => {
    const { dispatch } = this.props;
    profileClientX
      .get("v1/profile")
      .then((response) => {
        const responseData = response.data?.data;
        //  dispatch(setProfile(responseData));

        this.props.setUserProfile(responseData);
        this.setState({ profile: responseData });
        setUserAccessToken(accessToken);

        // history.push("/");
        // onLoginEvent(responseData?.mobile)
      })
      .catch((error) => {
        removeCookie("wst");
        localStorage.removeItem('wst')
        localStorage.removeItem('isMob')
        localStorage.removeItem('is_kamaie')
        //Remove localStorage.setItem('wst', queryWst);
        console.log(error);
      });
  };
  openNav = () => {
    this.setState({ isSidenavOpen: true });
  };
  closeNav = () => {
    this.setState({ isSidenavOpen: false });
    this.setState({ voucherBlock: false, affiliatesBlock: false });
  };

  handleDrpDownOnClick = (value) => {
    this.props.history.push(value);
    const element = document.querySelectorAll(".menu-level-options-container");
    element.forEach((element) => {
      element.classList.add("menu-close");
      element.classList.remove("menu-open");
    });
  };
  MobileSidenavContent = () => {
    return (
      <Accordion id="sidenav-accordian">
        <Card className="py-1">
          <Accordion.Toggle
            as={Card.Header}
            variant="link"
            eventKey="1"
            className="m-0 p-0"
          >
            <button
              className="btn collapsed d-flex justify-content-between w-100"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <span>Explore</span>
              <span>
                <img style={{ width: "13px" }} src={right_arrow} alt="arrow" />
              </span>
            </button>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <div className="pl-2">
              <Accordion id="sidenav-accordian">
                <Card className="m-0 p-0">
                  <Accordion.Toggle
                    as={Card.Header}
                    variant="link"
                    eventKey="1"
                    className="m-0 p-0"
                  >
                    <button
                      className="btn collapsed d-flex justify-content-between w-100"
                      onClick={() => this.setState({ voucherBlock: true })}
                    >
                      <span className="nav-text-gray cursor-pointer">
                        {" "}
                        Vouchers
                      </span>
                      <span>
                        <img
                          style={{ width: "13px" }}
                          src={right_arrow}
                          alt="arrow"
                        />
                      </span>
                    </button>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} className="m-0 p-0">
                    <button
                      className="btn nav-text-gray w-100 text-start"
                      type="button"
                      onClick={() => {
                        this.props.history.push(`/nearby-partners`);
                        this.closeNav();
                      }}
                    >
                      Nearby Partners
                    </button>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} className="m-0 p-0">
                    {/* <button className="btn nav-text-gray w-100" type="button" onClick={() => {
                      this.props.history.push(`/shopping-sites`);
                      this.closeNav()
                    }}>
                      Shopping Sites
                    </button> */}
                    <button
                      className="btn collapsed d-flex justify-content-between w-100"
                      onClick={() => this.setState({ affiliatesBlock: true })}
                    >
                      <span className="nav-text-gray cursor-pointer">
                        {" "}
                        Shopping Sites
                      </span>
                      <span>
                        <img
                          style={{ width: "13px" }}
                          src={right_arrow}
                          alt="arrow"
                        />
                      </span>
                    </button>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} className="m-0 p-0">
                    <button
                      className="btn nav-text-gray w-100"
                      type="button"
                      onClick={() => {
                        this.props.history.push(`/bill-pay`);
                        this.closeNav();
                      }}
                    >
                      Bill Pay
                    </button>
                  </Accordion.Toggle>
                </Card>
              </Accordion>
            </div>
          </Accordion.Collapse>
        </Card>
        <Card className="py-1">
          <Accordion.Toggle as={Card.Header} className="m-0 p-0">
            <button
              className="btn collapsed d-flex justify-content-between w-100"
              onClick={() => {
                this.props.history.push(`/orders`);
                this.closeNav();
              }}
            >
              <span>Orders</span>
              <span>
                <img style={{ width: "13px" }} src={right_arrow} alt="arrow" />
              </span>
            </button>
          </Accordion.Toggle>
        </Card>
        <Card className="py-1">
          <Accordion.Toggle as={Card.Header} className="m-0 p-0">
            <button className="btn collapsed d-flex justify-content-between w-100">
              <span className="w-100">
                <LanguageSwitcher />
              </span>
              <span>
                <img style={{ width: "13px" }} src={right_arrow} alt="arrow" />
              </span>
            </button>
          </Accordion.Toggle>
        </Card>
      </Accordion>
    );
  };
  MobileVoucherContent = () => {
    return (
      <div className="voucher-section pt-2" id="voucherSection">
        <div
          className="h6 px-4 d-flex justify-content-between"
          id="headingFour"
        >
          <p className="m-0" style={{ fontSize: "20px" }}>
            <span
              className="mr-2"
              onClick={() => this.setState({ voucherBlock: false })}
            >
              <span className="mr-2 cursor-pointer">
                <img
                  style={{ width: "13px" }}
                  src={ic_back_arrow}
                  alt="arrow"
                />
              </span>
            </span>
            <span>
              <strong>Vouchers</strong>
            </span>
          </p>
          <span>
            <img style={{ width: "13px" }} src={ic_down_arrow} alt="arrow" />
          </span>
        </div>
        <div className="px-4 py-2 nav-text-gray">
          {this.props?.verticalCategory?.voucher.map((item) => {
            return (
              <p
                className="cursor-pointer"
                onClick={() => {
                  this.props.history.push(item.value);
                  this.closeNav();
                }}
              >
                {item.label}
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  MobileAffiliatesContent = () => {
    return (
      <div className="voucher-section pt-2" id="affiliatesSection">
        <div
          className="h6 px-4 d-flex justify-content-between"
          id="headingFour"
        >
          <p className="m-0" style={{ fontSize: "20px" }}>
            <span
              className="mr-2"
              onClick={() => this.setState({ affiliatesBlock: false })}
            >
              <span className="mr-2 cursor-pointer">
                <img
                  style={{ width: "13px" }}
                  src={ic_back_arrow}
                  alt="arrow"
                />
              </span>
            </span>
            <span>
              <strong>Shopping Sites</strong>
            </span>
          </p>
          <span>
            <img style={{ width: "13px" }} src={ic_down_arrow} alt="arrow" />
          </span>
        </div>
        <div className="px-4 py-2 nav-text-gray">
          {this.props?.verticalCategory?.affiliate.map((item) => {
            return (
              <p
                className="cursor-pointer"
                onClick={() => {
                  this.props.history.push(item.value);
                  this.closeNav();
                }}
              >
                {item.label}
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const isMob = getIsMob();
    const is_kamaie = getIsKamaie();
    const exploreDropdownContent = {
      placeholder: (
        <div
          className={`d-flex nav-link explore-home ${
            this.props?.location?.pathname === "/" ? "active" : ""
          }`}
        >
          <FormattedMessage
            id="navbar.explore"
            defaultMessage="Explore"
            description="Explore"
            values={{ what: "react-intl" }}
          />
          <svg
            fill={`${
              this.props?.location?.pathname === "/" ? "#6372ff " : "#3f4850"
            }`}
            className="explore-btn-svg"
            viewBox="0 0 256 256"
            id="Flat"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#CCCCCC"
              strokeWidth="4.096"
            />
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path d="M213.65674,101.657l-80,79.99976a7.99945,7.99945,0,0,1-11.31348,0l-80-79.99976A8,8,0,0,1,48,88H208a8,8,0,0,1,5.65674,13.657Z" />{" "}
            </g>
          </svg>
        </div>
      ),
      options: [
        {
          label: `Vouchers`,
          hidden: false,
          options: this.props?.verticalCategory?.voucher,
        },
        {
          value: "/nearby-partners",
          label: "Nearby Partners",
          hidden: false,
        },
        {
          // value: '/shopping-sites',
          label: "Shopping Sites",
          hidden: false,
          options: this.props?.verticalCategory?.affiliate,
        },
        {
          value: "/bill-pay",
          label: "Bill Pay",
          hidden: false,
        },
      ],
    };

    return (
      <React.Fragment>
        {isMob != 1 && is_kamaie != 1 && (
          <>
            <div
              id="mobile-location"
              className="container"
              style={{
                backgroundColor: "#fff",
                boxShadow: "0 0 20px 0 rgba(0,0,0,0.25)",
              }}
            >
              <div className="row font-proxima-nova align-items-center">
                <div className="col-8 mx-auto d-md-none">
                  <LocationAddon style={{ lineHeight: "30px" }} />
                </div>
              </div>
            </div>
            <div className="site-header">
              <div className="container font-proxima-nova ">
                <nav className="navbar navbar-expand-lg row">
                  <div className="col-md-1 col-2 toggle-nav">
                    <Link className="navbar-brand d-none d-md-block" to="/">
                      <img src={logoIcon} alt="Winds" />
                    </Link>
                    <div>
                      <span
                        style={{ fontSize: "30px", cursor: "pointer" }}
                        onClick={this.openNav}
                        className="d-block d-md-none"
                      >
                        <span>
                          <img
                            style={{ width: "23px" }}
                            src={ic_hamburger}
                            alt="arrow"
                          />
                        </span>
                      </span>
                    </div>
                    <div
                      id="mySidenav"
                      className={`sidenav ${
                        this.state.isSidenavOpen ? "open" : ""
                      }`}
                    >
                      <div className="d-flex justify-content">
                        <span className="closebtn" onClick={this.closeNav}>
                          &times;
                        </span>
                        <span
                          className="toggle-home-icon"
                          onClick={() => {
                            this.props.history.push(`/`);
                            this.closeNav();
                          }}
                        >
                          <img
                            src={logoIcon}
                            style={{ width: "30px" }}
                            alt="Winds"
                          />
                        </span>
                      </div>
                      <div className="shadow-line"></div>

                      {this.state.voucherBlock ? (
                        <this.MobileVoucherContent />
                      ) : this.state.affiliatesBlock ? (
                        <this.MobileAffiliatesContent />
                      ) : (
                        <this.MobileSidenavContent />
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <div>
                      <div
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#EFEFF4",
                          borderRadius: "8px",
                        }}
                      >
                        <span
                          className="input-group-text"
                          style={{ border: "none", borderRadius: "8px" }}
                        >
                          <LocationAddon />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-10 ml-auto">
                    <ul className="navbar-nav ml-auto flex-row justify-content-between">
                      <li className="d-md-flex nav-item text-center d-none ">
                        <div>
                          <DropDownMenuSelect
                            values={exploreDropdownContent}
                            handleOnClick={this.handleDrpDownOnClick}
                          />
                        </div>
                      </li>
                      <li className="d-md-flex nav-item text-center d-none ">
                        <NavLink
                          exact
                          className="nav-link"
                          to="/orders"
                          activeclassname="is-active"
                        >
                          <span className="">
                            <FormattedMessage
                              id="navbar.orders"
                              defaultMessage="Orders"
                              description="Orders"
                              values={{ what: "react-intl" }}
                            />
                          </span>
                        </NavLink>
                      </li>
                      <li className="nav-item text-center">
                        <div className="nav-link d-none d-md-block">
                          <LanguageSwitcher />
                        </div>
                      </li>
                      <li className="nav-item text-center">
                        <NavLink
                          className="nav-link"
                          to="/notifications"
                          activeclassname="is-active"
                          style={{ padding: 0 }}
                        >
                          <img
                            src={notificationIcon}
                            alt="Notifications"
                            title="Notifications"
                          />
                        </NavLink>
                      </li>
                      <li className="nav-item text-center">
                        <NavLink
                          className="nav-link"
                          to="/rewards"
                          activeclassname="is-active"
                        >
                          <img
                            src={rewardsIcon}
                            alt="Cashback/Rewards"
                            title="Cashback/Rewards"
                          />
                          {this.props?.rewards && this.props.profile ? (
                            <span className="header-rewards">
                              {this.props?.rewards}
                            </span>
                          ) : (
                            <></>
                          )}
                        </NavLink>
                      </li>
                      <li className="nav-item text-center">
                        <NavLink
                          style={{ paddingLeft: "0", textDecoration: "none" }}
                          className="nav-link"
                          to="/winds-coins"
                          activeclassname="is-active"
                        >
                          <div className="wrp-coin">
                            <span>
                              {this.props.profile &&
                              this.props?.wrp?.wrp?.confirmed_wrp
                                ? this.props?.wrp?.wrp?.confirmed_wrp
                                : 0}
                            </span>
                            <img
                              className="wrp-coin-img"
                              src={wrpNewCoin}
                              alt="Cashback/Rewards"
                              title="Cashback/Rewards"
                            />
                          </div>
                        </NavLink>
                      </li>
                      <Avatar />
                    </ul>
                  </div>
                </nav>
              </div>
              {/* <div className='w-100 d-flex justify-content-center navbar--winds-icon'>
          <img src={logoIcon} style={{ width: "25px" }} alt="Winds"/>
        </div> */}
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  getCurrentUser: state.global,
  profile: state.global.profile,
  wrp: state.wrp,
  rewards: state.home.rewards,
  verticalCategory: state.global?.verticalCategory,
});

function mapDispatchToProps(dispatch) {
  return {
    getVerticalCategory: () => {
      customerCommonClientX
        .get("v2/all_services_category")
        .then((response) => {
          const data = response?.data?.data;
          const vouchers = data?.voucher.map((item) => ({
            value: `/vouchers/category/${item.slug}`,
            label: item.value,
            hidden: false,
          }));
          const affiliate = data?.affiliate.map((item) => ({
            value: `/shopping-sites/category/${item.slug}`,
            label: item.name,
            hidden: false,
          }));
          const categoriesData = {
            voucher: vouchers,
            affiliate: affiliate,
          };
          dispatch(getVerticalCategory(categoriesData));
        })
        .catch((error) => {});
    },
    setUserProfile: (profileData) => {
      dispatch(setProfile(profileData));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
