import React from 'react';
import { useParams,useLocation} from 'react-router-dom';
import Iframe from './iFrame';

const Featured_products = ({source}) => {
    const location = useLocation();
    const redirect_url = location.state?.redirect_url || source; 
  return (
    <div>
      <Iframe source={redirect_url} />
    </div>
  );
};

export default Featured_products;