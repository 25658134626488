import React, { Component } from "react";
import { Link } from "react-router-dom";
import customerAffiliateClientX from "../../../utils/api/customerAffiliateClientX";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import vectorImg from "../../../assets/shopping-sites-bill-upload-vector.svg";
import InlineLoader from "../../../components/InlineLoader";
import { FormattedMessage, injectIntl } from "react-intl";
import { onBillUploaded } from "../../../utils/events";
import { showToastMessage } from "../../../utils/helper";
import { connect } from "react-redux";
import moment from "moment";
import VideoInstructionModal from "../../../components/VideoInstructionModal";
import { readCookie } from "../../../utils/helper";
import { getIsMob, getIsKamaie } from "../../../utils/helper";
class BillUpload extends Component {
  files = [];
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      previewFiles: [],
      shoppingSites: [],
      shoppingSite: -1,
      amount: null,
      product_name: null,
      order_date: null,
      bill: null,
      file_extension: null,
      file_type: null,
      isUploading: false,
      isAmtInValid: false,
      isVideoModalOpen: false,
      
    };
  }

  componentDidMount() {

    if (this.props.profile) {
      customerAffiliateClientX
        .get(`api/v1/bills/affiliates`)
        .then((response) => {
          let data = response.data.data;

          this.setState(
            {
              shoppingSites: data,
            },
            () => {
              const siteCode = this.props.match.params.id;
              if (siteCode) {
                this.setState({ shoppingSite: siteCode });
              }
            }
          );
        });
    } else {
      this.props.history.push('/login')
    }
  }

  closeVideoModel = () => {
    this.setState({
      isVideoModalOpen: false,
    });
  };
  openVideoModel = () => {
    this.setState({
      isVideoModalOpen: true,
    });
  };

  onSelectBill = (event) => {
    this.setState({
      bill: event.target.files[0],
    });
  };


  onUpload = () => {
    let that = this;
    const amount = this.state.amount;
    const productName = this.state.product_name;
    const orderDate = this.state.order_date;
    this.setState({ isUploading: true }, () => {
      const filesArray = this.state.files;
      let bill_image_ids = [];
      Promise.all(
        filesArray.map(function(file) {
          let formData = new FormData();
          formData.append("file", file);
          return customerAffiliateClientX
            .request({
              url: `/api/v2/bills/file/upload`,
              method: "post",
              data: formData,
            })
            .then((response) => {
              const data = response.data.data;
              bill_image_ids.push(data.id);
            })
            .catch((error) => {
              this.setState({
                isUploading: false,
              });
            });
        })
      )
        .then(function(finished) {
          customerAffiliateClientX
            .request({
              url: `/api/v2/bills/${that.state.shoppingSite}`,
              method: "post",
              data: {
                total_order_amount: amount,
                product_name: productName,
                order_date: orderDate,
                bill_image_ids: bill_image_ids,
              },
            })
            .then((response) => {
              const payload = {
                Category: "Shopping Site",
                "Product Name": productName,
                Amount: that.state.amount,
                Date: response.data.data.created_at,
              };
              onBillUploaded(payload);
              toast.success(
                <FormattedMessage
                  id="general.bill-upload"
                  defaultMessage="You bill was uploaded successfully."
                />,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                }
              );
              that.setState({
                shoppingSite: -1,
                amount: null,
                product_name: null,
                order_date: null,
                bill: null,
                isUploading: false,
              });
              that.props.history.push(
                `/shopping-sites/orders/${response.data.data.id}`
              );
            })
            .catch((error) => {
              that.setState({
                isUploading: false,
              });
            });
        })
        .catch((error) => {
          that.setState({
            isUploading: false,
          });
        });
    });
  };

  uploadMultipleFiles = (e) => {
    e.persist();
    const filesArray = [];
    filesArray.push(e.target.files);
    for (
      let i = 0, filesArrayLength = filesArray.length;
      i < filesArrayLength;
      i++
    ) {
      this.setState((prevState) => ({
        previewFiles: prevState.previewFiles.concat(
          URL.createObjectURL(filesArray[i][0])
        ),
        files: prevState.files.concat(filesArray[i][0]),
      }));
    }
  };

  deleteFile = (index) => {
    this.setState({
      previewFiles: this.state.previewFiles.filter((file, i) => i !== index),
      files: this.state.files.filter((file, i) => i !== index),
    });
  };

  onAmountEnter = (event) => {
    const { value } = event.target;
    if (value > 9999999) {
      showToastMessage("error", "Only upto 9999999 is allowed");
      this.setState({ isAmtInValid: true });
    } else {
      this.setState({ isAmtInValid: false });
    }
    this.setState({ amount: value });
  };

  render() { 
    const isMob=getIsMob()
    const is_kamaie=getIsKamaie()
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              {isMob != 1 && is_kamaie!= 1 && (
                <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="shopping-sites.upload-bill-for-shopping-sites"
                      defaultMessage="Upload Bill for Shopping Sites"
                    />
                  </strong>
                </small>
              </div> 
              )}
              
            </div>
          </div>
          {this.state.isVideoModalOpen && (
            <VideoInstructionModal onClose={this.closeVideoModel} />
          )}
        </div>

        <div className="row mb-3 mb-md-4">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body ">
                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="shopping-sites.choose-shopping-site"
                          defaultMessage="Shopping Site"
                        />
                      </label>
                      <select
                        className="form-control"
                        value={this.state.shoppingSite}
                        onChange={(event) => {
                          this.setState({ shoppingSite: event.target.value });
                        }}
                      >
                        <option value="-1">
                          {this.props.intl.formatMessage({
                            id: "general.choose",
                          })}
                        </option>
                        {this.state.shoppingSites.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>
                        {" "}
                        <FormattedMessage
                          id="general.amount"
                          defaultMessage="Amount"
                        />
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={
                          null == this.state.amount ? "" : this.state.amount
                        }
                        onChange={this.onAmountEnter}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="shopping-sites.product-name"
                          defaultMessage="Product Name"
                        />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          null == this.state.product_name
                            ? ""
                            : this.state.product_name
                        }
                        onChange={(event) => {
                          this.setState({ product_name: event.target.value });
                        }}
                        maxLength={255}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="shopping-sites.order-date"
                          defaultMessage="Order Date"
                        />
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        max={moment().format("YYYY-MM-DD")}
                        value={
                          null == this.state.order_date
                            ? ""
                            : this.state.order_date
                        }
                        onChange={(event) => {
                          this.setState({ order_date: event.target.value });
                        }}
                      />
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <h5>Upload Bill Image</h5>
                        <p
                          style={{
                            fontSize: "14px",
                            fontStyle: "italic",
                            marginTop: "-.5rem",
                          }}
                        >
                          Please note that you can upload a maximum of 5 bill
                          images 
                        </p>
                        {/* <button
                        className="btn btn-primary btn-block home-btn claim-btn"
                        
                        onClick={this.openVideoModel}
                      >
                        <FormattedMessage
                          id="general.upload-instruction"
                          defaultMessage="Upload Instruction"
                        />
                      </button> */}
                      </div>
                    </div>
                    <div className="bill-upload mt-2 d-flex">
                      <label htmlFor="upload-photo" className="upload-box">
                        <span className="plus">+</span>
                        <span className="winds-lightblue-color">
                          <FormattedMessage
                            id="payNow.add-image"
                            defaultMessage="Add Image"
                          />
                        </span>
                      </label>
                      <input
                        type="file"
                        name="photo"
                        id="upload-photo"
                        onChange={this.uploadMultipleFiles}
                        multiple
                        disabled={this.state.previewFiles.length > 4}
                        value=""
                      />

                      {(this.state.previewFiles || []).map((file, index) => (
                        <div
                          className="upload-box"
                          key={`previewAffilateBillImage${index}`}
                        >
                          <div className="close-btn winds-lightblue-bg d-flex">
                            <i
                              className="fas fa-times"
                              onClick={() => this.deleteFile(index)}
                            ></i>
                          </div>
                          <img src={file} alt="..." title="file" />
                        </div>
                      ))}
                    </div>
                    {this.state.isUploading ? (
                      <div className="text-center">
                        <InlineLoader />
                      </div>
                    ) : (
                      <button
                        className="btn btn-primary btn-block home-btn claim-btn"
                        disabled={
                          null == this.state.shoppingSite ||
                          0 === this.state.files.length ||
                          this.state.isAmtInValid ||
                          this.state.order_date === null ||
                          this.state.order_date === ""
                        }
                        onClick={this.onUpload}
                      >
                        <FormattedMessage
                          id="general.upload"
                          defaultMessage="Upload"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <img
                  className="mt-3 mt-md-0 shopping-sites-bill-upload-vector"
                  src={vectorImg}
                  alt="upload shopping sites bill"
                  title="upload shopping sites bill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}

export default connect(mapStateToProps, null)(injectIntl(BillUpload));
