import React, { Component } from "react";
import profileClientX from "../../../utils/api/profileClientX";
import gatewayClientX from "../../../utils/api/gatewayClientX";
import axios from "axios";
import ContentLoader from "react-content-loader";
import editIcon from "../../../assets/profile/ic_edit_profile_image.svg"
import deleteIcon from "../../../assets/delete.svg";
import { toast } from "react-toastify";
import update from "immutability-helper";
// import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { setProfile } from "../../../actions";
import verified from "../../../assets/profile/ic_verified.png"
class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      isLoading: false
    };
  }

  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        profileClientX.get("v1/profile").then(response => {
          this.props.setProfile(response.data.data);
          this.setState({
            //     profile: response.data.data,
            isLoading: false
          });
        });
      }
    );
  };

  verifyAccount = () => { };

  onFileChange = e => {

    let file = e.target.files[0];
    let file_name = file.name;
    let extension = file.name.split(".").pop();
    let file_type = file.type;
    const pattern = /image-*/;
    if (!file_type.match(pattern)) {
      toast.error('"Only PNG, JPG, JPEG and PDF are supported"', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    gatewayClientX
      .request({
        url: `v1/profile-picture/pre-signed-url`,
        method: "post",
        data: {
          file_name: file_name,
          file_extension: extension,
          mime_type: file_type
        }
      })
      .then(response => {
        let key = response.data.key;

        axios.put(response.data.url, file).then(response => {
          gatewayClientX
            .post(`v1/profile-picture`, {
              pic: key
            })
            .then(response => {
              this.fetchProfile();
              toast.success("Profile updated Successfully")
            })
            .catch(error => {
            });
        });
      });
  };

  deleteHandler = () => {
    gatewayClientX
      .delete("v1/profile-picture")
      .then(response => {
        let profile = this.props.profile;
        profile.profile_pic_url = null;
        let newState = update(this.state, {
          profile: { $set: profile }
        });
        this.setState(newState);
      })
      .catch(error => {

      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-10 col-md-11 m-auto font-proxima-nova-bold ">
          {this.state.isLoading ? (
            <div style={{ height: "100px", overflow: "hidden" }}>
              <ContentLoader
                height="100px"
                width="400px"
                speed={2}
                primaryColor="#EDECF2"
                secondaryColor="#ecebeb"
              > 
                <rect x="120" y="30" rx="4" ry="4" width="117" height="15" />
                <rect x="120" y="60" rx="3" ry="3" width="85" height="10" />
                <circle cx="50" cy="50" r="50" />
              </ContentLoader>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {null !== this.props.profile && (
                <React.Fragment>
                  <div
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50px",
                      marginRight: "15px",
                      boxShadow: "0 3px 8px 0 rgba(0,0,0,0.43)",
                      cursor: "pointer",
                      display: "inline-block",
                      backgroundImage: `url(${this.props.profile?.profile_pic_url})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      position: "relative"
                    }}
                  >
                    {!this.props.profile?.profile_pic_url && (
                      <React.Fragment>
                        <label className="custom-file-upload">
                          <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={this.onFileChange}
                            accept=".png, .jpg, .jpeg"
                          />
                          <div className="icon-wrapper">
                            <img
                              src={editIcon}
                              alt="edit"
                              title="edit"
                              style={{
                                marginTop: "9px",
                                marginLeft: "9px",
                                float: "none",
                                cursor: "pointer"
                              }}
                              onClick={this.uploadHandler}
                            />
                          </div>
                        </label>

                        <div className="avatar">
                          <p>
                            {this.props.profile?.name
                              .substring(0, 1)
                              .toUpperCase()}
                          </p>
                        </div>
                      </React.Fragment>
                    )}

                    {this.props.profile?.profile_pic_url && (
                      <div className="icon-wrapper">
                        <img
                          src={deleteIcon}
                          alt="edit"
                          title="edit"
                          style={{
                            marginTop: "7px",
                            marginLeft: "9px",
                            float: "none",
                            cursor: "pointer"
                          }}
                          onClick={this.deleteHandler}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ display: "inline-block", marginLeft: ".4rem", color: "#fff" }}>
                    <p
                      className="mb-0"
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      {this.props.profile?.name}
                    </p>
                    <p className="mb-0">+91 {this.props.profile?.mobile}</p>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <div className="col-2 col-md-1 p-0">
          {
            (this.props.profile?.basic_profile_progress_percent === 100 && this.props.profile?.preference_percentage === 100) &&
            <img className="" src={verified} width="100%" alt="verified" title="verified" />
          }
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setProfile: (responseData) => {
      dispatch(setProfile(responseData));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);

