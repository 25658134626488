import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import bannerClientX from "../../../utils/api/bannerClientX";
import WindsLoader from "../../../components/WindsLoader";
import InlineLoader from "../../../components/InlineLoader";
import { useHistory } from "react-router-dom";
import hotTag from "../../../assets/tag-hot.gif";
import newTag from "../../../assets/tag-new.gif";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
import { getIsKamaie, getIsMob } from "../../../utils/helper";

const Deals = () => {
  const [dealsList, setDealsList] = React.useState([]);
  const ref = React.useRef();
  let history = useHistory();
  const [isLoading, setLoading] = React.useState(false);
  const [pageNo, setPageNo] = React.useState(0);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [isLast, setLast] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    bannerClientX
      .get(`/v1/deals`, {
        params: {
          platform: "web",
          featured: 0,
          page: 1,
        },
      })
      .then((response) => {
        setDealsList(response.data.data.deals);
        setLoading(false);
        setLoadingMore(false);
      });
  }, []);

  React.useEffect(() => {
    if (!isLast && pageNo > 1 && !isLoading) {
      setLoadingMore(true);
      bannerClientX
        .get(`/v1/deals`, {
          params: {
            platform: "web",
            featured: 0,
            page: pageNo,
          },
        })
        .then((response) => {
          setLoadingMore(false);
          setLoading(false);
          response.data.data.deals.length > 0 &&
            setDealsList(dealsList.concat(response.data.data.deals));
          response.data.data.meta.last_page === pageNo && setLast(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setPageNo((pageNo) => pageNo + 1);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  const shopNow = (deal) => {
    const id = deal.id;
    const { segment, value } = deal.payload;
    history.push(`${segment}/${value}/${id}`);
  };
  const isMob = getIsMob("isMob");
  const is_kamaie = getIsKamaie("is_kamaie");
  return (
    <div className="container fluid font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        {isMob != 1 && is_kamaie != 1 && (
          <div className="col-12">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>{" "} /{" "}
              <strong><FormattedMessage id="breadcrumbs.deals" defaultMessage="Deals of the Day"/></strong>
            </small>
          </div>
        )}
      </div>
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4 deals-page">
        {isLoading ? (
          <WindsLoader rows={4} columns={10} height={50} width={300} padding={1} speed={1} />
        ) : dealsList && dealsList.length > 0 ? (
          dealsList.map((deal, index) => {
            return (
              <div className=" custom-col deal-head " key={`dealno${index}`}  >
                {/* col-12 col-sm-6 col-md-3 */}
                <div className="deal-card">
                  <div className="deal-body bg-white">
                    <div className="px-1 position-relative">
                      <img src={deal.image_url} alt="product" title="product" style={{ borderRadius: "10px" }}/>
                      <div className="d-flex justify-content-center">
              <div className=" position-center position-absolute" style={{ top: "92%",  left: "50%", transform: "translateX(-50%)", backgroundColor: "white", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.05)",}} >
                <img src={deal.brand_image} alt="brand logo" title="brand logo" className=" deals-brand-logo brands-logo "/>
              </div>
              </div> 
                    </div>
                    <div className="col-12 pt-3">
                      <h6 className="deal-title text-dark pt-1">{deal.product_name}</h6>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="col-2 d-flex mr-3 mr-md-3  mx-xxl-1" style={{ textDecoration: "line-through" }}>
                          <h6 className="mrp align-items-center">₹{deal.actual_price}</h6>
                        </div>
                        <div className="col-2 text-danger d-flex mx-2 mx-xxl-1">
                          <h6 className="offer-price">₹{deal.offer_price}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div className="deal-card-header">
                      {deal.tag === "hot" && (<img src={hotTag} alt="tag" title="tag" className="tag-img"/>)}
                      {deal.tag === "new" && (<img src={newTag} alt="tag" title="tag" className="tag-img"/>)}
                    </div>
                  <div className="deal-footer col-12 pb-2">
                    <button className="btn btn-dark border-rounded py-2 w-100" style={{ borderRadius: "25px" }} onClick={() => shopNow(deal)} >
                      <FormattedMessage id="general.shop-now" defaultMessage="SHOP NOW"/>
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="empty-state">
            <EmptyState title={ <FormattedMessage id="deals.no-deal" defaultMessage="No deals at the moment"/>} imageWidth={30} image={EmptyImage}/>
          </div>
        )}
        {loadingMore}
        {loadingMore && <InlineLoader />}
        <p ref={ref} id="loadingIcon"></p>
      </div>
    </div>
  );
};
export default Deals;
