import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import AddAccount from "../components/AddAccount";
import deleteIcon from "../../../assets/delete.svg";
import walletClientX from "../../../utils/api/walletClientX";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { getIsKamaie, getIsMob } from "../../../utils/helper";
const WithdrawMoney = () => {
  const [balance, setBalance] = useState("");
  const [isConfirmationDialogOpen, toggleConfirmationDialog] = useState(false);
  const [selectedBankAccount, setBankAccount] = useState(null);
  const [isAccountModelOpen, toggleAccountModel] = useState(false);
  const [accounts, setAccountsList] = useState([]);
  //const [isLoading, setLoading] = useState(true);
  const [selectedAccountType, setAccountType] = useState("");
  const openAddAccount = () => {
    toggleAccountModel(true);
  };
  const isMob=getIsMob("isMob")
  const is_kamaie=getIsKamaie("is_kamaie")
  const deleteBank = (account) => {
    setBankAccount(account);
    toggleConfirmationDialog(true);
  };
  const closeAccountModal = (isTransactionCompleted) => {
    toggleAccountModel(false);
    // setBankAccount(null);
    isTransactionCompleted && getWalletBalance();
  };
  const changeAccountType = (event) => {
    setAccountType(event.target.value);
    setBankAccount(null)
  };

  const onConfirmDeleteBank = () => {
    const selectedAccountId = selectedBankAccount.id;
    walletClientX
      .delete(`/v1/bank-accounts/${selectedAccountId}`)
      .then((response) => {
        const modifiedAccountList = accounts.filter(
          (account) => account.id !== selectedAccountId
        );
        //  setLoading(false);
        setAccountsList(modifiedAccountList);
        setBankAccount(null);
      });
  };
  const onDismissDeleteBank = () => {
    toggleConfirmationDialog(false);
  };

  useEffect(() => {
    getBanks();
    getWalletBalance();
  
  }, []);

  const getWalletBalance = () => {
    walletClientX.get("/v1/wallet").then((response) => {
      setBalance(response.data.data.balance);
    });
  };

  const getBanks = () => {
    walletClientX.get(`/v1/bank-accounts`).then((response) => {
      //   setLoading(false);
      setAccountsList(response.data.data);
    });
  };

  const selectAccount = (account) => {
    setBankAccount(account);
  };
  return (
    <div style={{ backgroundColor: "#F2F6FA" }} className="pb-5">
      <div className="container font-proxima-nova">
        <div className="row pt-3 mb-3">
         {(isMob!=1 && is_kamaie!=1 &&
           <div className="col-12">
           <div className="row">
             <div className="col-12">
               <small>
                 <Link to="/">
                   <FormattedMessage
                     id="breadcrumbs.home"
                     defaultMessage="Home"
                   />
                 </Link>
               </small>{" "}
               /{" "}
               <small>
                 <Link to="/wallet">
                   <FormattedMessage
                     id="wallet.wallet"
                     defaultMessage="Wallet"
                   />
                 </Link>
               </small>{" "}
               /{" "}
               <small>
                 <strong>
                   <FormattedMessage
                     id="wallet.withdraw-money"
                     defaultMessage="Withdraw Money"
                   />
                 </strong>
               </small>
             </div>
           </div>
           <div className="row mt-3 mb-3 wallet">
             <div className="col-12">
               <div className="row mt-md-4 mb-md-4">
                 <div className="col-8">
                   <p className="mb-0">
                     <FormattedMessage
                       id="wallet.wallet-balance"
                       defaultMessage="Wallet Balance"
                     />
                   </p>
                   <h3>
                     <strong>₹ {balance}</strong>
                   </h3>
                 </div>
                 <div className="col-4 d-flex justify-content-end">
                   {selectedAccountType !== "SAVING" && (
                     <button
                       className="btn btn-primary btn-block mb-3 mt-1 mt-sm-0 home-btn w-auto px-5"
                       disabled={!selectedAccountType}
                       onClick={openAddAccount}
                     >
                       <FormattedMessage
                         id="general.continue"
                         defaultMessage="Continue"
                       />
                     </button>
                   )}

                   {isAccountModelOpen && (
                     <AddAccount
                       onCloseAccountModal={(isTransactionCompleted) =>
                         closeAccountModal(isTransactionCompleted)
                       }
                       selectedBank={selectedAccountType === "SAVING" && selectedBankAccount}
                       balance={parseFloat(balance.replace(",", ""))}
                     />
                   )}
                 </div>
               </div>
               <div className="row mt-4 mb-2">
                 <div className="col-12">
                   <h6 className="mb-0">
                     <FormattedMessage
                       id="wallet.select-account"
                       defaultMessage="Select Account"
                     />
                   </h6>
                 </div>
               </div>
               <div className="row mt-md-2 mb-md-2">
                 <div className="col-12 col-md-6">
                   {accounts && accounts.length > 0 && (
                     <div className="form-check form-check-inline">
                       <input
                         className="form-check-input mr-3 cursor-pointer"
                         type="radio"
                         id="saved-acct"
                         name="accountType"
                         value="SAVING"
                         onChange={changeAccountType}
                       />
                       <label className="form-check-label cursor-pointer" htmlFor="saved-acct">
                         <FormattedMessage
                           id="wallet.saved-account"
                           defaultMessage="Saved Account"
                         />
                       </label>
                     </div>
                   )}
                   <div className="form-check form-check-inline ml-5">
                     <input
                       className="form-check-input mr-3 cursor-pointer"
                       type="radio"
                       id="new-acct"
                       name="accountType"
                       value="NEW"
                       onChange={changeAccountType}
                     />
                     <label className="form-check-label cursor-pointer" htmlFor="new-acct">
                       <FormattedMessage
                         id="wallet.new-account"
                         defaultMessage="New account"
                       />
                     </label>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
         )}
        </div>
      </div>
      {selectedAccountType === "SAVING" && (
        <div className="container font-proxima-nova bg-white p-4 p-md-5" style={{ borderRadius: "15px" }}>
          <div className="row mb-3">
            {/* <div className="col-md-6 col-12 account-box border rounded pt-3 pb-5 wallet"> */}

            <h4>
              <strong>
                <FormattedMessage
                  id="wallet.accounts"
                  defaultMessage="Accounts"
                />
              </strong>
            </h4>
          </div>
          <div className="row">
            {accounts &&
              accounts.length > 0 &&
              accounts.map((account, index) => {
                return (
                  <div className="col-md-6 col-12 account-box border rounded pt-3 wallet mb-3">
                    <div className="card border-0 mt-4" key={account.id}>
                      <div className="row pb-4 border-bottom">
                        <div className="col-1">
                          <input
                            className="cursor-pointer"
                            type="radio"
                            name="account"
                            value={account.id}
                            onChange={() => selectAccount(account)}
                          />
                        </div>
                        <div className="col-9">
                          <div className="card-body p-0">
                            <h6>{account.account_name}</h6>
                            <span>
                              <FormattedMessage
                                id="wallet.number-account"
                                defaultMessage="Account Number"
                              />
                              :&nbsp;{account.account_number}
                            </span>{" "}
                            <span>
                              <FormattedMessage
                                id="wallet.ifsc"
                                defaultMessage="IFSC"
                              />
                              :&nbsp;{account.ifsc}
                            </span>
                            <span>
                              {" "}
                              <FormattedMessage
                                id="wallet.branch-account"
                                defaultMessage="Bank Branch"
                              />
                              :&nbsp;{account.branch}
                            </span>
                            <span>
                              {" "}
                              <FormattedMessage
                                id="wallet.pan"
                                defaultMessage="PAN"
                              />
                              :&nbsp;{account.pan}
                            </span>
                          </div>
                        </div>
                        <div className="col-1">
                          <span
                            onClick={() => deleteBank(account)}
                            className="d-inline-block btn"
                          >
                            <img
                              src={deleteIcon}
                              className="align-text-top"
                              alt="delete icon"
                              title="delete icon"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {selectedBankAccount && (
              <ConfirmationDialog
                isConfirmationDialogOpen={isConfirmationDialogOpen}
                title={
                  <>
                    <FormattedMessage
                      id="wallet.delete-bank"
                      defaultMessage="Delete Bank"
                    />
                  </>
                }
                body={
                  <>
                    <FormattedMessage
                      id="wallet.delete-bank-confirmation"
                      defaultMessage="Are you sure, you want to delete "
                    />{" "}
                    <strong>{selectedBankAccount.account_number}</strong>
                  </>
                }
                buttonTxt1={
                  <>
                    <FormattedMessage
                      id="profile.confirm"
                      defaultMessage="Confirm"
                    />
                  </>
                }
                buttonTxt2={
                  <>
                    <FormattedMessage
                      id="profile.cancel"
                      defaultMessage="Cancel"
                    />
                  </>
                }
                onSuccess={onConfirmDeleteBank}
                onDismiss={onDismissDeleteBank}
              />
            )}

          </div>
          <div className="row">
            <div className="col-12 p-0">
              <button
                className="home-btn btn btn-primary mt-4 px-5 w-auto"
                disabled={!selectedBankAccount}
                onClick={openAddAccount}
              >
                <FormattedMessage
                  id="general.continue"
                  defaultMessage="Continue"
                />
              </button>
            </div>
          </div>
        </div>
        // </div>
      )}
    </div>
  );
};

export default WithdrawMoney;
