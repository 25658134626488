import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import profileClientX from "../../../utils/api/profileClientX";
import SideBar from "../components/SideBar";
import Profile from "../components/Profile";
import { FormattedMessage } from "react-intl";
import { ProgressBar } from "react-bootstrap";
import ic_alert_preference from "../../../assets/profile/ic_alert_preference.png";
import shopping_preference1 from "../../../assets/profile/ic_shopping_preference1.png";
import ic_alert_preference2 from "../../../assets/profile/ic_alert_preference2.png";
import shopping_preference2 from "../../../assets/profile/ic_shopping_preference2.png";
import { useState } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile } from "../../../actions";
import { toast } from "react-toastify";
import WrpCreditModal from "../components/WrpCreditModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function PreferenceDetails() {
  let dispatch = useDispatch()
  let history=useHistory()
  const [preferenceActive, setPreferenceActive] = useState(true)
  const [value, setValue] = useState([]);
  const [alertValue, setAlertValue] = useState([])
  const [valueLength, setValueLenth] = useState()
  const [alertValueLength, setAlertValueLenth] = useState()
  const [wrpCreditSuccessModal,setWrpCreditSuccessModal]=useState(false)
  const handleChange = (val) => { setValue(val) };
  const AlerthandleChange = (val) => { setAlertValue(val) }
  const { profile } = useSelector((state) => state?.global);

  useEffect(() => {
    setValueLenth(value.length)
  }, [value])

  useEffect(() => {
    setAlertValueLenth(alertValue.length)
  }, [alertValue])

  useEffect(() => {
    if (profile === null) {
      history.push('/login')
    }
    else {
      const selectedShoppingIDs = profile?.shopping_preference
        .filter(item => item.selected)
        .map(item => item.id);
      setValue(selectedShoppingIDs)

      const selectedAlertIDs = profile?.alert_preference
        .filter(item => item.selected)
        .map(item => item.id);
      setAlertValue(selectedAlertIDs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formData = {
    "name": profile?.name,
    "state_id": profile?.state_id,
    "shopping_preference": value,
  };
  const alertFormData = {
    "name": profile?.name,
    "state_id": profile?.state_id,
    "shopping_alert": alertValue,
  };
  const updatePreferenceDetails = () => {
    profileClientX
      .put(`v1/profile`, formData)
      .then((response) => {
        let responseData = response.data.data;
        dispatch(updateProfile(responseData))
        response.data.data?.wrp_credited_status_for_preference_profile ?
        setWrpCreditSuccessModal(true) :
        toast.success("Your Preference Details is Updated!")
      })
      .catch((error) => {
        toast.error("Something went wrong!")
      });
  }
  const updateAlertDetails = () => {
    profileClientX
      .put(`v1/profile`, alertFormData)
      .then((response) => {
        let responseData = response.data.data;
        dispatch(updateProfile(responseData))
        response.data.data?.wrp_credited_status_for_preference_profile ?
        setWrpCreditSuccessModal(true) :
        toast.success("Your Preference Details is Updated!")
      })
      .catch((error) => {
        toast.error("Something went wrong!")
      });
  }


  const ShoppingContent = () => {
    return (
      <>
        <div className="text-center w-100 mt-4">
          <h5 className="m-0">
            <strong>
              Please select your top five shopping preferences.
            </strong>
          </h5>
          <p><small>Shopping Preference will customize offers based on your preference and can serve you better </small></p>
        </div>

        <ToggleButtonGroup type="checkbox" value={value} onChange={handleChange} className="mb-4 d-flex flex-wrap toggle-group">
          {profile?.shopping_preference?.map((item, index) =>
            <ToggleButton className="m-2 rounded-pill" value={item.id} variant="outline-light" key={index}>{item.name}</ToggleButton>
          )}
        </ToggleButtonGroup>

        <div className="text-center w-100">
          <small>A minimum of 5 interest must be chosen.</small><br />
          <button className={valueLength < 5 ? "animated-btn-disabled w-50 mt-2" : "animated-btn w-50 mt-2"} onClick={() => updatePreferenceDetails()}>DONE</button>
        </div>
      </>
    )
  }

  const AlertContent = () => {
    return (
      <>
        <div className="text-center w-100 mt-4">
          <h5><strong>Alert Preference</strong></h5>
        </div>

        <ToggleButtonGroup type="checkbox" value={alertValue} onChange={AlerthandleChange} className="mb-4 d-flex flex-wrap toggle-group">
          {profile?.alert_preference?.map((item, index) =>
            <ToggleButton className="m-2 rounded-pill" value={item.id} variant="outline-light">{item.name}</ToggleButton>
          )}
        </ToggleButtonGroup>

        <div className="text-center w-100">
          <button className={alertValueLength < 1 ? "animated-btn-disabled w-50" : "animated-btn w-50"} onClick={() => updateAlertDetails()}>DONE</button>
        </div>
      </>
    )
  }
  return (

    <>
      <React.Fragment>
        <div className="w-100 profile-container-head">
          {wrpCreditSuccessModal && profile?.preference_alerts_completion_wrp  !==0 &&  <WrpCreditModal desc="For completing your Preference Details" wrp={profile?.preference_alerts_completion_wrp}/>}
          <div className="container pt-3 pb-3 pt-md-4 pb-md-4">
            <div className="row mb-md-3">
              <div className="col-12 m-auto font-proxima-nova text-white">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/profile">
                    <FormattedMessage
                      id="profile?.profile"
                      defaultMessage="Profile"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>Preference Details</strong>
                </small>
              </div>
            </div>

            <div className="d-none d-md-block">
              <Profile />
            </div>
          </div>
        </div>

        <div className="container mt-md-5 mb-4 mb-md-5">
          <div className="row mb-3 mt-md-4 mb-md-4">
            <div className="d-none col-md-4 d-md-block font-proxima-nova border-right">
              <SideBar />
            </div>
            <div className="col-12 col-md-8 d-md-block py-3 py-md-0">
              <div className="row d-md-flex">
                <div className="col-12 font-proxima-nova-bold">
                  <h5 >
                    <strong>
                      <p>Preference Details</p>
                    </strong>
                  </h5>
                  {
                    profile?.preference_percentage !== 100 ?
                      (
                        <>
                          <p className=" m-0 d-flex justify-content-between profile-text-blue ">
                            {
                              profile?.preference_alerts_completion_wrp === 0 ?
                              <span><small>Complete your Preferences</small></span>:
                              <span><small>Complete your Preferences and win {profile?.preference_alerts_completion_wrp} WINDS Coins</small></span>
                            }
                            <span className="p-0 m-0 ">
                              <strong>{profile?.preference_percentage}%</strong>
                            </span>
                          </p>
                          <ProgressBar
                            now={profile?.preference_percentage}
                            className="profile-progress-bar my-2"
                            variant="progress-bar-style"
                          />
                        </>
                      ) :
                      <p className="completed-profile">Your preference details completed!</p>
                  }

                  <div className="d-flex justify-content-center  w-100 mx-auto mt-3">
                    <div className="row w-100 g-3 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-light d-flex justify-content-start align-content-center py-2 col-11 col-md-5 my-2 rounded"
                        id={preferenceActive ? "btn-shopping-preference-main" : "btn-light btn-preference-light"}
                        onClick={() => setPreferenceActive(true)}
                        style={{ borderColor: "#8660f236" }}
                      >
                        <span className="mr-2">
                          <img src={preferenceActive ? shopping_preference1 : shopping_preference2} alt="preference" title="preference" />
                        </span>
                        <span>
                          <strong>Shopping Preference</strong>
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-light d-flex justify-content-start align-content-center py-2 col-11 col-md-5 my-2 rounded"
                        id={preferenceActive ? "btn-light btn-preference-light" : "btn-shopping-preference-main"}
                        onClick={() => setPreferenceActive(false)}
                        style={{ borderColor: "#8660f236" }}
                      >
                        <span className="mr-2">
                          <img src={preferenceActive ? ic_alert_preference : ic_alert_preference2} alt="preference" title="preference" />
                        </span>
                        <span>
                          <strong>Alert Preference</strong>
                        </span>
                      </button>
                    </div>
                  </div>
                  {preferenceActive ? ShoppingContent() : AlertContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}
