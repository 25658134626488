import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { FormattedMessage } from "react-intl";
import Lottie from "lottie-react";

import { showToastMessage } from "../utils/helper";
import aapple from "../assets/apple-en.png";
import windLogo from "../assets/windLogo.png";
import rightArrow from "../assets/rightArrow.png";

import flipcartLogo from '../assets/flipcartLogo.png'
import rightAnimation from '../assets/rightAnimation.gif'
import ic_success from '../assets/profile/ic_success.svg'
const DealsCart = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      // window.open(dummy[0].Redirect_to, '_blank');
    });

    return () => clearTimeout(timer);
  }, []);

  const dummy = [
    {
      id: 802,
      image_url:
        "https://winds-app-files-prod.s3.ap-south-1.amazonaws.com/deals/app/127244128362849effc28ac277030154.png",
      brand_image:
        "https://winds-app-files-prod.s3.ap-south-1.amazonaws.com/deals/thumb/hcdSSL9HpdxnfVR6jwK2nljdnSkhZ9sqnd6n1rW1.png",
      product_name: "PERFORMAX Textured Lace-Up Sports Shoes",
      actual_price: 1799,
      offer_price: 1299,
      offer: false,
      coupan: true,
      coupan_code: "CQFPG1968J",
      app: "WINDSAPP",
      clickApp: "Flipcart",
      desc_deal_cart: "Read and accept the terms and conditions",
      desc_deal_cart_two:
        "The shopping website will open as a new window within the Winds App",
      desc_deal_cart_three:
        "Winds will automatically track your purchase and reward you",
      desc_deal_cart_four:
        "WINDS Coins will be credited to your Winds account once order refund window is expied.",
      Redirect_to: "https://www.amazon.in/",
      logo:
        "https//winds-app-files-prod.s3.ap-south-1.amazonaws.com/uploads/affiliate/image/thumb/pn1tybkfRbUy2HzEbW79W41uu7BD5SOvKinIndRm.png",
    },
  ];
  return (
    <>
      {dummy.map((data, index) => {
        return (
          <div className="" key={`dealno${index}`}>
            {/* <a href={data.Redirect_to}> */}
            <div className="container ">
              <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
                <div className="col-12">
                  <small>
                    <Link to="/">
                      <FormattedMessage
                        id="breadcrumbs.home"
                        defaultMessage="Home"
                      />
                    </Link>{" "}
                    /{" "}
                    <strong>
                      <FormattedMessage
                        id="deals-cart"
                        defaultMessage="Customer commission"
                      />
                    </strong>
                  </small>
                </div>
              </div>

              {loading && (
                 <>
                   <div className="row justify-content-center pt-4  pb-3 ">
                        <div className="col-12 col-md-4 justify-content-center ">
                          <div className=" d-flex rounded" style={{backgroundColor:"rgb(46,38,56)"}}>
                            
                            <div className="d-flex align-items-center">
                              <img src={windLogo} alt="image" className="w-100 px-3 py-3"/>
                            </div>
                            <div className="d-flex justify-content-center">
                            
                            <img src={rightAnimation} alt="rightAnimation" className="rightArrow"/>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <img src={flipcartLogo} alt="animation" className="w-100 px-3 py-3"/>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="row ">
                          <div className="col-12">
                            <h5 className="text-center deals-taking pt-md-3">
                              <strong>
                                Taking you to{" "}
                                <span style={{ color: "rgb(238,187,80)" }}>
                                  {data.clickApp}
                                </span>
                              </strong>{" "}
                            </h5>
                          </div>
                        </div>
                     
                        <div className="row pb-md-2">
                          <div className="col-12">
                            <div>
                              <p className="text-center ">
                                Carry on Shopping as usual
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-md-2">
                          <div className="col-12">
                            <div className="">
                              <h6 className="text-center deal-activated">
                                The Deal is ACTIVATED
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center ">
                          <div className="col-12 d-flex justify-content-center ">
                            {data.offer ? (
                              <div className="d-flex justify-content-center ">
                                <h6
                                  className="text-center px-5 py-2  deals-noCode"
                                  style={{
                                    border: "dashed",
                                    backgroundColor: "rgb(255,250,238)",
                                  }}
                                >
                                  No code required{" "}
                                </h6>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <div className="col-sm-6 d-flex justify-content-center ">
                                  <button
                                    className=" px-5 py-2"
                                    style={{
                                      border: "dashed",
                                      backgroundColor: "rgb(255,250,238)",
                                      fontSize:"1.5rem"
                                    }}
                                  >
                                    {data.coupan_code}
                                  </button>
                                </div>
                                <div className="col-sm-5 d-flex justify-content-center">
                                  <button
                                    className=" rounded btn btn-info border text-white px-4"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        data.coupan_code
                                      );
                                      showToastMessage(
                                        "success",
                                        "Code copied to the clipboard"
                                      );
                                    }}
                                  >
                                    Copy
                                  </button>
                                  <span
                                    className="font-weight-bold d-flex align-items-center justify-content-between cursor-pointer"
                                    style={{ color: "#6372ff" }}
                                  ></span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row deal-mask d-flex justifycontent-center pb-md-5">
                          <div className="col-12 pb-5">
                            <div className="d-flex justify-content-center pb-5 pt-2">
                              <div className="spinner-border text-info" role="status">
                                <span className="sr-only"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                          <div className="d-flex align-items-center justify-content-center">
                              <span className="mx-md-3 text-danger">
                                Not Redirecting?
                              </span>
                              <button className="btn btn-info">
                                Go to Offer page
                              </button>
                            </div> 
                          </div>
                        </div>
                 </>
                     
              )}
            </div>
            <div className="container border rounded my-4 ">
            {!loading && (
              <div className="">
                  <>
         
         <div className="row rounded" style={{backgroundColor:"rgb(46,38,56)"}}>
              <div className="col-2">
                <img src={flipcartLogo} alt="logo" className="w-50 my-2"/>
              </div>
              <div className="col-10 d-flex  align-items-center ">
              <span className="text-white">The Big Fashion Sale: 40% - 80% off + Extra Rs 400 off ( SEP 18-19 )</span>
              </div>
          </div>
          <div className="row py-md-3">
            <div className="col-12">
              <div className="">
                <h3>Best Buy</h3>
                <span>.com Domain @ Rs 559 Only</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <h5>Your Offer Has Been Activated On The Website Already</h5>
              </div>

            </div>

          </div>
          <div className="row">
              <div className="col-12 col-sm-6">
              <div className="d-flex align-items-center justify-content-center py-sm-2 py-1 my-3 my-sm-3 rounded"
                style={{  border: "dashed",  backgroundColor: "rgb(255, 250, 238  )",borderColor:"rgb(119,172,8)"}} >
                  <img src={ic_success} alt="success"/>
                  <span
                    className=" mx-2 text-center  text-success " style={{fontSize:"25px"}} >
                    Offer Activated
                  </span>
                </div>
              </div>
          </div>
          <div className="row">
            <div className="col-0 col-sm-1"></div>
            <div className="col-12 col-sm-4 ">
              <div className="">
              <button className="animated-btn "> Visit Site & Earn WINDS Coin</button> 
              </div>
             </div>
          </div>
          <hr className="mt-4" style={{borderTop:" 1px dashed #000"}}></hr>
          <div className="row">
            <div className="col-12">
              <div>
                <h3>Ongoing offers from Flipkart</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
                <ol>
                  <li>
                    Get instant discount up to 55% OFF on your orders
                  </li>
                  <li>Also, get an extra 10% OFF with a coupon code</li>
                  <li>
                    Get instant discount up to 55% OFF on your orders
                  </li>
                </ol>
              </div>
              <div className="col-12">
                <div><span>
                Note : Please add products to cart only after clicking "Visit Site & Earn Rewards"
                </span>
                </div>
              </div>
          </div>
      
        
         </>
         
              </div>
        
         
 
       )}
            </div>
            
          
            {/* </a> */}
          </div>
        );
      })}
    </>
  );
};
function mapStateToProps(state) {
  // console.log(state.home.deal_of_the_day,"amol")
  return {
    profile: state.global.profile,
    deal_of_the_day: state.home.deal_of_the_day,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DealsCart);
