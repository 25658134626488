import React from "react";
import Slider from "react-slick";
import winds_logo from '../../../assets/winds_Logo.svg'


export default function TestimonialCarousal() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        responsive: [
            {
                breakpoint:700,
                settings: {
                    // dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint:1000,
                settings: {
                    // dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            
        ],
    };
    return (
        <div className="home-page section-space" style={{ backgroundColor: "#EAE4FF" }}>
            <div className="container" >
                <div className="row ">
                    <div className="col-9 pb-3">
                        <h2 className="title">What our <span style={{ color: "#1DD771" }}>happy customers have to say</span></h2>
                    </div>
                </div>
                
                <Slider {...settings}>

                    <div className="pt-5">
                        <div className="row justify-content-center">
                            <div className="col-10 testimonial-reviw-box px-4 pb-4 px-sm-5 pb-sm-5 pt-0" >
                                <div style={{ marginTop: "-40px", marginBottom: "10px" }} className="d-flex flex-row justify-content-between align-items-end">
                                <div style={{ borderRadius: "80px", width: "110px",height:"110px",backgroundColor:"#6978fd",color:"#fff"}} className="pb-n5 d-flex justify-content-center align-items-center"><p className="h1 m-0 "><strong>ML</strong></p></div>

                                    {/* <img src={dummy_profile} alt="profile" style={{ borderRadius: "80px", width: "110px" }} className="pb-n5" /> */}
                                    <img src={winds_logo} alt="logo" title="logo" style={{width:"40px"}}/>
                                </div>
                                <p className="h3"><strong>Manohar Lal Panwar</strong></p>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    {/* <p className=" text-center h5 m-0 px-2 px-sm-3 w-100" style={{ color: "#1DD771", borderRight: "2px solid #D8D8D8" }}>Enterprenaur</p> */}
                                    <div className=" container d-flex justify-content-start testimonial-star-ratings w-100">
                                        <div className="row">
                                            <div className="col-md-12 p-0 px-1 ">
                                                <div className="stars">
                                                    <form action="">
                                                        <input className="star star-5" id="star-5" type="radio" name="star" checked="checked"  />
                                                        <label className="star star-5" htmlFor="star-5"></label>
                                                        <input className="star star-5" id="star-4" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-4"></label>
                                                        <input className="star star-5" id="star-3" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-3"></label>
                                                        <input className="star star-5" id="star-2" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-2"></label>
                                                        <input className="star star-5" id="star-1" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-1"></label>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w-75 mr-0"/>
                                <div style={{ marginTop: "-40px", marginBottom: "10px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#3F4850" className="bi bi-quote" viewBox="0 0 16 16" >
                                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
                                    </svg>
                                </div>
                                <p className="h6 m-0">
                                    <em>I am very thankful to Winds family from whom I earned this amount of one lakh rupees for the first time in my life. I will continue to use winds application in the same way in future and will try to tell people further so that they too become winners of this huge amount, I once again say a lot to winds family </em>
                                </p>
                            </div>
                        </div>
                    </div>
    
                    
                    <div className="pt-5">
                        <div className="row justify-content-center">
                            <div className="col-10 testimonial-reviw-box px-4 pb-4 px-sm-5 pb-sm-5 pt-0" >
                                <div style={{ marginTop: "-40px", marginBottom: "10px" }} className="d-flex flex-row justify-content-between align-items-end">
                                    {/* <img src={dummy_profile} alt="profile" style={{ borderRadius: "80px", width: "110px" }} className="pb-n5" /> */}
                                    <div style={{ borderRadius: "80px", width: "110px",height:"110px",backgroundColor:"#6978fd",color:"#fff"}} className="pb-n5 d-flex justify-content-center align-items-center"><p className="h1 m-0 "><strong>HS</strong></p></div>
                                    <img src={winds_logo} alt="logo" title="logo" style={{width:"40px"}}/>
                                </div>
                                <p className="h3"><strong>Hasnain Shaikh</strong></p>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <div className=" container d-flex justify-content-start testimonial-star-ratings w-100">
                                        <div className="row">
                                            <div className="col-md-12 p-0 px-1 ">
                                                <div className="stars">
                                                    <form action="">
                                                        <input className="star star-5" id="star-5" type="radio" name="star" checked="checked"/>
                                                        <label className="star star-5" htmlFor="star-5"></label>
                                                        <input className="star star-5" id="star-4" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-4"></label>
                                                        <input className="star star-5" id="star-3" type="radio" name="star"  />
                                                        <label className="star star-5" htmlFor="star-3"></label>
                                                        <input className="star star-5" id="star-2" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-2"></label>
                                                        <input className="star star-5" id="star-1" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-1"></label>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w-75 mr-0"/>
                                <div style={{ marginTop: "-40px", marginBottom: "10px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#3F4850" className="bi bi-quote" viewBox="0 0 16 16" >
                                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
                                    </svg>
                                </div>
                                <p className="h6 m-0">
                                    <em>
                                    I came across the WINDS App in the month of (October, 2022). Since then, I have been using the application to make my utility bill payments & shop online from shopping sites like Myntra and Swiggy. And guess what? I won a whooping cash prize of ₹1 Lakh in the month of (March, 2023) via their Monthly Lucky Draw
                                    </em>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="row justify-content-center">
                            <div className="col-10 testimonial-reviw-box px-4 pb-4 px-sm-5 pb-sm-5 pt-0" >
                                <div style={{ marginTop: "-40px", marginBottom: "10px" }} className="d-flex flex-row justify-content-between align-items-end">
                                    {/* <img src={dummy_profile} alt="profile" style={{ borderRadius: "80px", width: "110px" }} className="pb-n5" /> */}
                                    <div style={{ borderRadius: "80px", width: "110px",height:"110px",backgroundColor:"#6978fd",color:"#fff"}} className="pb-n5 d-flex justify-content-center align-items-center"><p className="h1 m-0 "><strong>K</strong></p></div>
                                    <img src={winds_logo} alt="logo" title="logo" style={{width:"40px"}}/>
                                </div>
                                <p className="h3"><strong>Kanhaiyalaal</strong></p>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <div className=" container d-flex justify-content-start testimonial-star-ratings w-100">
                                        <div className="row">
                                            <div className="col-md-12 p-0 px-1 ">
                                                <div className="stars">
                                                    <form action="">
                                                        <input className="star star-5" id="star-5" type="radio" name="star" checked="checked"/>
                                                        <label className="star star-5" htmlFor="star-5"></label>
                                                        <input className="star star-5" id="star-4" type="radio" name="star"  />
                                                        <label className="star star-5" htmlFor="star-4"></label>
                                                        <input className="star star-5" id="star-3" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-3"></label>
                                                        <input className="star star-5" id="star-2" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-2"></label>
                                                        <input className="star star-5" id="star-1" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-1"></label>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w-75 mr-0"/>
                                <div style={{ marginTop: "-40px", marginBottom: "10px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#3F4850" className="bi bi-quote" viewBox="0 0 16 16" >
                                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
                                    </svg>
                                </div>
                                <p className="h6 m-0">
                                    <em>
                                    Using WINDS App daily is one of the best habits I have developed over the past few months. I did my daily shopping via WINDS App and earned instant cash rewards via Spin Wheel. Not just that, I also won a cash prize worth ₹1 Lakh in the month of (Month 2023). Don't miss out on this rewarding app.
                                    </em>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="row justify-content-center">
                            <div className="col-10 testimonial-reviw-box px-4 pb-4 px-sm-5 pb-sm-5 pt-0" >
                                <div style={{ marginTop: "-40px", marginBottom: "10px" }} className="d-flex flex-row justify-content-between align-items-end">
                                    {/* <img src={dummy_profile} alt="profile" style={{ borderRadius: "80px", width: "110px" }} className="pb-n5" /> */}
                                    <div style={{ borderRadius: "80px", width: "110px",height:"110px",backgroundColor:"#6978fd",color:"#fff"}} className="pb-n5 d-flex justify-content-center align-items-center"><p className="h1 m-0 "><strong>AS</strong></p></div>
                                    <img src={winds_logo} alt="logo" title="logo" style={{width:"40px"}}/>
                                </div>
                                <p className="h3"><strong>Alok Singh</strong></p>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <div className=" container d-flex justify-content-start testimonial-star-ratings w-100">
                                        <div className="row">
                                            <div className="col-md-12 p-0 px-1 ">
                                                <div className="stars">
                                                    <form action="">
                                                        <input className="star star-5" id="star-5" type="radio" name="star" checked="checked"/>
                                                        <label className="star star-5" htmlFor="star-5"></label>
                                                        <input className="star star-5" id="star-4" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-4"></label>
                                                        <input className="star star-5" id="star-3" type="radio" name="star"  />
                                                        <label className="star star-5" htmlFor="star-3"></label>
                                                        <input className="star star-5" id="star-2" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-2"></label>
                                                        <input className="star star-5" id="star-1" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-1"></label>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w-75 mr-0"/>
                                <div style={{ marginTop: "-40px", marginBottom: "10px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#3F4850" className="bi bi-quote" viewBox="0 0 16 16" >
                                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
                                    </svg>
                                </div>
                                <p className="h6 m-0">
                                    <em>
                                    I came across the WINDS App in the month of (May'2022). Since then, I have been using the application to make my utility bill payments & shop online from shopping sites like Myntra and Nykaa, Amazon, Flipkart, Zomato, Swiggy etc. And guess what? I won a whooping cash prize of ₹1 Lakh in the month of (June, 2022) via their Monthly Lucky Draw. Later on also till date I have been rewarded against my purchases up to 2Lac + in total reward amount. Absolutely thrilled to be a part of Winds
                                    </em>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="row justify-content-center">
                            <div className="col-10 testimonial-reviw-box px-4 pb-4 px-sm-5 pb-sm-5 pt-0" >
                                <div style={{ marginTop: "-40px", marginBottom: "10px" }} className="d-flex flex-row justify-content-between align-items-end">
                                    {/* <img src={dummy_profile} alt="profile" style={{ borderRadius: "80px", width: "110px" }} className="pb-n5" /> */}
                                    <div style={{ borderRadius: "80px", width: "110px",height:"110px",backgroundColor:"#6978fd",color:"#fff"}} className="pb-n5 d-flex justify-content-center align-items-center"><p className="h1 m-0 "><strong>SA</strong></p></div>
                                    <img src={winds_logo} alt="logo" title="logo" style={{width:"40px"}}/>
                                </div>
                                <p className="h3"><strong>Sanjeev Agarwal</strong></p>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <div className=" container d-flex justify-content-start testimonial-star-ratings w-100">
                                        <div className="row">
                                            <div className="col-md-12 p-0 px-1 ">
                                                <div className="stars">
                                                    <form action="">
                                                        <input className="star star-5" id="star-5" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-5"></label>
                                                        <input className="star star-5" id="star-4" type="radio" name="star" checked="checked"/>
                                                        <label className="star star-5" htmlFor="star-4"></label>
                                                        <input className="star star-5" id="star-3" type="radio" name="star"  />
                                                        <label className="star star-5" htmlFor="star-3"></label>
                                                        <input className="star star-5" id="star-2" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-2"></label>
                                                        <input className="star star-5" id="star-1" type="radio" name="star" />
                                                        <label className="star star-5" htmlFor="star-1"></label>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w-75 mr-0"/>
                                <div style={{ marginTop: "-40px", marginBottom: "10px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#3F4850" className="bi bi-quote" viewBox="0 0 16 16" >
                                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
                                    </svg>
                                </div>
                                <p className="h6 m-0">
                                    <em>
                                    I came across the WINDS App in the month of (June 2022). Since then, I have been using the application to make my utility bill payments & shop online from shopping sites like Myntra and Nykaa. And guess what? I won a whooping cash prize of ₹1 Lakh in the month of (July 2022) via their Monthly Lucky Draw.
                                    </em>
                                </p>
                            </div>
                        </div>
                    </div>

                </Slider>
            </div>
        </div>
    );
}
