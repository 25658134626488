import React from "react";
import { useHistory } from "react-router-dom";
import MultiItemCarousel from "../../../components/MultiItemCarousel";
import hotTag from "../../../assets/tag-hot.gif";
import newTag from "../../../assets/tag-new.gif";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FormattedMessage } from "react-intl";
const DealsCorousel = (props) => {
  let history = useHistory();
  const { deals } = props;
  const shopNow = (deal) => {
    const id = deal.id;
    const { segment, value } = deal.payload;
    history.push(`deals-cart/${id}`);
    // history.push('/deals-cart')
  };

  return (
       <MultiItemCarousel>
     
     {deals.map((deal, index) => {
       return (
        <Link to={{ pathname: `/deals-cart/${deal.id}`, state: { from: "/" } }} key={index}>
            <div className="" key={`dealno${index}`}  >
        <div className="deal-card " >
          <div className="deal-body bg-white">
            <div className="mx-2 position-relative">
              <img src={deal.image_url} alt="product" title="product" style={{ borderRadius: "10px" }}/>
              <div className="d-flex justify-content-center">
              <div className=" position-center position-absolute" style={{ top: "86%",left: "50%", transform: "translateX(-50%)", backgroundColor: "white", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.05)",}} >
                <img src={deal.brand_image} alt="brand logo" title="brand logo" className=" deal-brand-logo  px-1"/>
              </div>
              </div> 
            </div>
            <div className="col-12 pt-3 pt-md-4">
              <h6 className="deal-title text-dark pt-1">{deal.product_name}</h6>
            </div>
            <div className="row">
              <div className="col-12 d-flex">
                <div className="col-2 d-flex mr-3 mr-md-3 mx-xxl-1" style={{ textDecoration: "line-through" }}>
                  <h6 className="mrp align-items-center">₹{deal.actual_price}</h6>
                </div>
                <div className="col-2 text-danger d-flex mx-2 mx-xxl-1">
                  <h6 className="offer-prices">₹{deal.offer_price}</h6>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="deal-card-header">
            {deal.tag === "hot" && (<img src={hotTag} alt="tag" title="tag" className="tag-img"/>)}
            {deal.tag === "new" && (<img src={newTag} alt="tag" title="tag" className="tag-img"/>)}
          </div> */}
          <div className="deal-footer pb-2">
            <button className="btn btn-dark border-rounded py-2 w-100 deal-btn" style={{ borderRadius: "25px" }} onClick={()=>shopNow(deal)} >
              <FormattedMessage id="general.shop-now" defaultMessage="SHOP NOW"/>
            </button>
          </div>
        </div>
      </div>
        </Link>
      
       ); 
     })}
   </MultiItemCarousel>
  );
};

export default DealsCorousel;
